// color variants
@import "themes-vars.module.scss";

/**** Veriables ****/
$font-montserrat: "Montserrat", sans-serif;

// Colors
$white: #fff;
$black: #000;

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

// img {
//   max-width: 100%;
// }

/*********** Global Styling ***********/

/** Links **/
.link {
  display: inline-block;
  background: linear-gradient(117.85deg, #2fc1ff -3.19%, #2f53ff 142.59%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  font-family: $font-montserrat;
}

.link-2 {
  display: inline-block;
  background: linear-gradient(97.63deg, #657de0 0%, #66eae2 108.44%, #62c4e2 108.45%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
  font-family: $font-montserrat;
}
/** Links End **/

/** Icon Button **/
.icon-btn {
  min-width: 46px;
  min-height: 46px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 18px;
  border: 1px solid rgba(255, 255, 255, 0.08);
  background: rgba(255, 255, 255, 0.12);
}
/** Icon Button End **/

/** Text Alignment **/
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
/** Text Alignment End **/

/** Flex **/
.d-flex {
  display: flex;
}

.align-item-start {
  align-items: flex-start;
}
.align-item-end {
  align-items: flex-end;
}
.align-item-center {
  align-items: center;
}

.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}

.flex-flow {
  flex-flow: row wrap;
}

.gap-5 {
  gap: 5px;
}
.gap-10 {
  gap: 10px;
}
.gap-15 {
  gap: 15px;
}
.gap-20 {
  gap: 20px;
}
/** Flex End **/

/** Positions **/
.p-static {
  position: static;
}
.p-relative {
  position: relative;
}
.p-absolute {
  position: absolute;
}
.p-fixed {
  position: fixed;
}
.p-sticky {
  position: sticky;
}
/** Positions End **/

/** Margins **/
.m-0 {
  margin: 0 !important;
}
.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
/** Margins End **/

/** Paddings **/
.p-0 {
  padding: 0 !important;
}
.pt-20 {
  padding-top: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}
/** Paddings End **/

/** Modals **/
.modal-bottom {
  .modal-bottom-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(39, 43, 63, 1);
    padding: 26px 20px;
    border-radius: 36px 36px 0 0;
  }
  .MuiList-root {
    padding: 0;
    .MuiListItem-root {
      &:not(:last-child) {
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        margin-bottom: 15px;
      }
    }
    .MuiListItemText-root {
      margin: 0;
      .MuiTypography-root {
        font-size: 16px;
        font-family: $font-montserrat;
        color: $white;
      }
    }
    .MuiButtonBase-root {
      padding: 0;
      &:hover {
        background: transparent !important;
      }
    }
  }
  &.modal-bottom-filters {
    .modal-bottom-content {
      padding: 26px 0 0;
      // min-height: 570px;
      height: 570px;
    }
  }
  .modal-bottom-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 0 20px 10px;
    margin-bottom: 10px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 20px;
      width: calc(100% - 40px);
      height: 1px;
      background-color: rgba(255, 255, 255, 0.1);
    }
    .title {
      font-size: 18px;
      font-weight: 600;
      color: $white;
      font-family: $font-montserrat;
    }
    .icon {
      cursor: pointer;
    }
  }
  .filters-tabs-wrap {
    display: flex;
    .filters-tabs {
      flex: 1 1 121px;
      width: 121px;
      min-width: 121px;
      max-width: 121px;
      .MuiTabs-indicator {
        display: none;
      }
    }
    .filters-tabs-content {
      flex: 1 1 auto;
    }
    .MuiTabs-flexContainer {
      border-bottom: 0;
      .MuiButtonBase-root {
        color: $white;
        font-weight: 500;
        min-height: 37px;
        overflow: visible;
        position: relative;
        &:not(:last-child) {
          margin-bottom: 10px;
          &::after {
            content: "";
            position: absolute;
            bottom: -5px;
            left: 20px;
            width: calc(100% - 40px);
            height: 1px;
            background: rgba(255, 255, 255, 0.1);
          }
        }
        &.Mui-selected {
          background: rgba(60, 64, 82, 1);
        }
      }
    }
    .filters-tabs-content-wrap {
      padding: 10px 15px;
      flex: 1 1 auto;
      .filters-tabs-content {
        height: 100%;
      }
      .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 23px;
        color: $white;
        margin-bottom: 20px;
      }
      .filters-sort-wrap {
        display: flex;
        justify-content: space-between;
        gap: 15px;
        height: calc(100% - 100px);
        flex-direction: column;
      }
      .filters-btns-wrap {
        display: flex;
        align-items: center;
        flex-flow: wrap row;
        height: calc(100% - 60px);
        overflow: scroll;
        gap: 15px;
        .filters-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          min-height: 30px;
          padding: 7px 15px;
          border-radius: 8px;
          color: $white;
          font-weight: 600;
          font-size: 12px;
          background: rgba(255, 255, 255, 0.12);
          border: 1px solid rgba(255, 255, 255, 0.08);
          cursor: pointer;
          svg {
            display: none;
          }
          &.selected {
            background: rgba(255, 255, 255, 0.3);
            svg {
              display: block;
            }
          }
        }
      }
      .filters-apply-btn {
        background: rgba(255, 255, 255, 0.12);
        border-color: rgba(255, 255, 255, 0.08);
        border-radius: 8px;
        color: $white;
        width: calc(100% - 60px);
        height: 38px;
        margin: 0 auto;
      }
    }
  }
}

.modal-main {
  // max-width: 324px;
  // margin: 0 auto;
  .modal-main-content {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 0;
    right: 0;
    background: rgba(24, 28, 31, 1);
    padding: 20px;
    margin: 20px;
    border-radius: 16px;
  }
  .modal-main-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
    position: relative;
    .title {
      font-size: 20px;
      font-weight: 500;
      color: $white;
    }
    .icon {
      background: rgba(65, 71, 75, 1);
      border-radius: 50%;
      width: 30px;
      min-width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .modal-main-body {
    .MuiFormControl-root {
      .MuiFormLabel-root {
        transform: translate(14px, 12px) scale(1);
        color: rgba(128, 128, 128, 1);
        &.Mui-focused,
        &.MuiFormLabel-filled {
          color: $primaryMain;
          transform: translate(22px, -9px) scale(0.75);
        }
      }
      .MuiInputBase-root {
        background: rgba(24, 28, 31, 1);
        border-radius: 6px;
      }
      .MuiInputBase-input {
        padding: 13px 15px;
        line-height: 20px;
      }
      .MuiSelect-select {
        color: rgba(128, 128, 128, 1);
        &[aria-expanded="true"] {
          & + input + .select-arrow {
            transform: rotate(180deg);
          }
        }
      }
      .MuiOutlinedInput-notchedOutline {
        // background: rgba(24, 28, 31, 1);
        border-radius: 6px;
        padding: 0 15px;
        border-color: rgba(117, 117, 117, 1);
      }
      .select-arrow {
        position: absolute;
        right: 15px;
        top: calc(100% - 26px);
        width: 12px;
        height: 6px;
        pointer-events: none;
        transition: all ease-in-out 0.3s;
      }
      .MuiFormHelperText-root {
        margin-inline: 0;
        text-align: right;
      }
    }
    .btn-wrap {
      .signbuttonMarket {
        min-height: 48px;
        border-radius: 15px;
      }
    }
  }
}
/** Modals End **/

/*********** Main Styling ***********/

/** Wrapper **/
.wrapper {
  // background: linear-gradient(60deg, black, rgba(255, 0, 0, 0), rgba(43, 140, 255, 0.30));
  background-image: url(../images/mobile-images/main-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  // TODO: Should test in screens after main search completed
  // min-height: 100vh;
  overflow-x: hidden;
  position: relative;
  .union-icon {
    position: absolute;
    top: 0;
    right: 0;
    // TODO: add if needed
    // z-index: 2;
  }
  &:has(.main-footer) {
    margin-bottom: 55px;
  }
}
/** Wrapper End **/

/** Auth Wrapper **/
.auth-wrapper {
  // background: linear-gradient(60deg, black, rgba(255, 0, 0, 0), rgba(43, 140, 255, 0.30));
  background-image: url(../images/mobile-images/main-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  padding: 40px 20px;
  overflow-x: hidden;
  position: relative;
  .circle-icon {
    position: absolute;
    top: 0;
    left: 0;
  }
}
/** Auth Wrapper End **/

/** Auth Main Header **/
.auth-main-header {
  .main-logo {
    svg {
      width: 100px;
      height: 45px;
    }
  }
}
/** Auth Main Header End **/

/** Main Header **/
.main-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px;
  position: relative;
  z-index: 2;
  .header-right-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
  .menu-btn {
    min-width: 46px;
    min-height: 46px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 18px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    background: rgba(255, 255, 255, 0.12);
    &.login-btn {
      min-width: 90px;
    }
  }
}
/** Main Header End **/

/** Main Footer **/
.main-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  .main-footer-inner {
    position: relative;
  }
  .MuiBottomNavigation-root {
    position: relative;
    z-index: 2;
    height: 80px;
    border: 1px solid rgba(205, 205, 205, 0.2);
    border-radius: 40px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    overflow: hidden;
    background: rgba(3, 4, 10, 1);
    .Mui-selected {
      // position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 25px;
        left: 50%;
        width: 50px;
        height: 35px;
        background: linear-gradient(180deg, #2f53ff 0%, #2fc1ff 100%);
        opacity: 0.3;
        border-radius: 50%;
        transform: translate(-25px, 0);
        filter: blur(13px);
      }
      svg {
        // TODO: Tow Tones icons custom
        // fill: #2F53FF !important;
        // path {
        //   stroke: #2FC1FF !important;
        // }
        fill: $primaryMain !important;
        path,
        circle {
          stroke: darken($primaryDark, 12%) !important;
        }
      }
    }
  }
  .swap-circle {
    position: absolute;
    top: -27px;
    left: 50%;
    transform: translate(-39px, 0);
    width: 80px;
    height: 80px;
    border: 2px solid rgba(205, 205, 205, 0.2);
    background: transparent;
    border-radius: 50%;
  }
  .swap-btn-wrap {
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translate(-30px, 0);
    z-index: 3;
    .swap-btn-wrap-inner {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: -9px;
        left: -9px;
        width: 80px;
        height: 80px;
        background: rgba(3, 4, 10, 1);
        border-radius: 50%;
      }
    }
    .swap-btn {
      background: linear-gradient(117.85deg, #2fc1ff -3.19%, #2f53ff 142.59%);
      width: 62px;
      height: 62px;
      position: relative;
      z-index: 4;
    }
  }
  .main-footer-alter {
    height: 100px;
    border-top: 1px solid rgba(205, 205, 205, 0.2);
    // border-radius: 60px;
    background: rgba(3, 4, 10, 1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 20px;
    del {
      color: rgba(138, 138, 138, 1);
      display: block;
      margin-bottom: 3px;
    }
    .title {
      font-size: 20px;
      font-weight: 600;
      color: $white;
      line-height: 26px;
      margin-bottom: 3px;
      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 26px;
      }
    }
    .link {
      background: linear-gradient(91.04deg, #305bff 0%, #309bff 101.48%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .signbuttonMarket {
      min-width: 126px;
      border-radius: 16px;
      min-height: 52px;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

/** Main Search Wrapper **/
.main-search-wrap {
  transition: all ease-in-out 0.4s;
  .union-icon {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    transition: all ease-in-out 0.4s;
  }
  .main-heading {
    display: none;
    transition: all ease-in-out 0.4s;
    .title {
      font-size: 30px;
      font-weight: 500;
    }
  }
  &.show {
    // background: linear-gradient(60deg, black, rgba(255, 0, 0, 0), rgba(43, 140, 255, 0.30));
    background-image: url(../images/mobile-images/main-bg.png);
    background-color: $black;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    & ~ * {
      display: none;
    }
    .main-heading {
      display: flex;
    }
    .union-icon,
    .main-search-history {
      display: block;
    }
    .main-search-wrap-inner {
      position: relative;
      padding: 20px;
    }
  }
}

.main-search {
  display: flex;
  align-items: center;
  gap: 3px;
  width: 100%;
  min-height: 58px;
  padding-inline: 10px;
  background: #23374b;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 18px;
  font-family: $font-montserrat;
  color: rgba(255, 255, 255, 0.36);
  margin-bottom: 30px;
  transition: all ease-in-out 0.4s;
  .search-input {
    font-size: 14px;
    width: 100%;
    border: none;
    padding-block: 8px;
    background: transparent;
    outline: none;
    color: currentcolor;
    &::placeholder {
      color: rgba(255, 255, 255, 0.36) !important;
    }
  }
}

.main-search-history {
  display: none;
  min-height: calc(100vh - 310px);
  overflow-x: hidden;
  transition: all ease-in-out 0.4s;
  .MuiList-root {
    padding: 0;
  }
  .MuiListItem-root {
    padding: 0;
  }
  .MuiButtonBase-root {
    padding-inline: 10px;
    .MuiListItemIcon-root {
      min-width: 30px;
      .MuiSvgIcon-root {
        font-size: 16px;
        color: rgba(50, 66, 82, 1);
      }
    }
    .MuiListItemText-root {
      margin: 0;
      .MuiTypography-root {
        color: $white;
        font-size: 16px;
      }
    }
  }
}
/** Main Search Wrapper End **/

/** Main Heading **/
.main-heading {
  margin-bottom: 20px;
  .title {
    font-family: $font-montserrat;
    font-size: 18px;
    font-weight: 600;
    color: $white;
  }
}

.main-subheading {
  border-bottom: 1px solid #252b2f;
  padding-bottom: 7px;
  margin-bottom: 20px;
  .title {
    position: relative;
    font-size: 16px;
    font-weight: 600;
    display: inline-block;
    &::before {
      content: "";
      position: absolute;
      bottom: -7px;
      left: 0;
      width: 100%;
      height: 4.5px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background: linear-gradient(138.3deg, #2f53ff -0.85%, #2fc1ff 131.63%);
    }
  }
}
/** Main Heading End **/

/** Main Banner **/
.main-banner {
  margin: 0 -20px;
  .main-banner-caption {
    position: relative;
    img {
      object-fit: fill;
      width: 100%;
      height: 273px;
      border-radius: 36px 36px 0 0;
    }
    .caption {
      position: absolute;
      background: rgba(0, 0, 0, 0.6);
      bottom: 0;
      left: 0;
      width: 100%;
      height: 93px;
      color: $white;
      padding: 10px 20px;
      .title {
        display: block;
        font-family: $font-montserrat;
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 4px;
      }
      p {
        font-family: $font-montserrat;
        font-size: 12px;
        margin: 0;
        svg {
          margin-left: 15px;
        }
      }
    }
  }
}
/** Main Banner End **/

/** Main Table Wrapper **/
.table-wrap {
  overflow-y: hidden;
  width: 100%;
  background: transparent;
  border-radius: 0;
  .MuiTable-root {
    min-width: 420px;
  }
  .MuiTableHead-root {
    .MuiTableCell-root {
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(24, 28, 31, 1);
      color: rgba(255, 255, 255, 0.7);
    }
  }
  .MuiTableCell-root {
    padding: 10px 0;
    font-weight: 500;
    .MuiTypography-root {
      font-weight: 500;
    }
  }
  .MuiTableBody-root {
    .MuiTableRow-root {
      .MuiTableCell-root {
        border-bottom: 0;
        color: rgba(255, 255, 255, 1);
      }
      &:not(:last-child) {
        .MuiTableCell-root {
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
  .table-pagination {
    color: $white;
    .MuiToolbar-root {
      padding: 20px 0 0;
    }
    .MuiInputBase-root {
      margin-right: 20px;
    }
    .MuiTablePagination-selectLabel {
      color: $white;
      margin: 0;
    }
    .MuiTablePagination-displayedRows {
      color: $white;
      margin: 0;
    }
  }
}
/** Main Table Wrapper End **/

/** Mobile Auth Wrapper **/
.access-auth-wrap {
  position: relative;
  z-index: 2;
  .auth-heading {
    font-family: $font-montserrat;
    font-size: 36px;
    font-weight: 500;
    color: $white;
    margin: 20px 0 12px;
    &.auth-heading-alter {
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 17px;
    }
  }
  .auth-description {
    font-size: 17px;
    font-family: $font-montserrat;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 20px;
  }
}

// Metamask Form Wrap
.meta-wallet-form-wrap {
  min-height: calc(100vh - 140px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
  .auth-form-btns-wrap {
    width: 100%;
  }
}

// Auth Form
.auth-input-wrap {
  &:has(.MuiFormHelperText-root) {
    .auth-form-control {
      margin-bottom: 5px;
    }
  }
  .auth-label {
    font-size: 16px;
    font-family: $font-montserrat;
    margin-bottom: 10px;
  }
  .auth-form-control {
    margin-bottom: 20px;
    & + .MuiFormHelperText-root {
      margin-bottom: 20px;
    }
    .auth-input {
      margin: 0;
      input {
        min-height: 56px;
        border-radius: 18px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        background: rgba(31, 42, 52, 1);
        padding: 0 25px;
        color: rgba(255, 255, 255, 0.36);
      }
      .MuiOutlinedInput-root {
        border-radius: 18px;
        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: $primaryMain;
          }
        }
      }
      .MuiInputBase-adornedEnd {
        min-height: 56px;
        border-radius: 18px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        background: rgba(31, 42, 52, 1);
        padding: 0 25px;
        color: rgba(255, 255, 255, 0.36);
        .MuiOutlinedInput-notchedOutline {
          margin: -1px;
        }
        input {
          min-height: 100%;
          border-radius: none;
          border: none;
          background: transparent;
          padding: 0;
          color: rgba(255, 255, 255, 0.36);
        }
      }
    }
  }

  // TODO: checkbox need to add styling
  // .MuiCheckbox-root {
  //   &.Mui-checked {
  //     background: linear-gradient(117.85deg, #2FC1FF -3.19%, #2F53FF 142.59%);
  //     -webkit-background-clip: text;
  //     -webkit-text-fill-color: transparent;
  //     color: linear-gradient(117.85deg, #2FC1FF -3.19%, #2F53FF 142.59%);
  //   }
  // }

  .MuiFormControlLabel-root {
    align-items: flex-start;
    margin: 0;
    .MuiButtonBase-root {
      padding: 0;
      margin-right: 7px;
    }
    .MuiTypography-root {
      color: $white;
      line-height: 24px;
      a {
        color: $white;
        font-weight: 600;
        &:hover {
          color: $primaryDark;
        }
      }
    }
  }

  .react-code-input {
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 5px;
    input {
      width: 100% !important;
      height: 90px !important;
      border-radius: 18px !important;
      border: 1px solid rgba(255, 255, 255, 0.1) !important;
      background: rgba(31, 42, 52, 1) !important;
      text-align: center !important;
      color: rgba(255, 255, 255, 0.36) !important;
      &:focus-visible {
        outline: none !important;
        box-shadow: none !important;
        border-color: $primaryMain !important;
      }
      &[data-valid="false"] {
        border-color: $errorMain !important;
        color: $errorMain !important;
      }
    }
  }

  &.auth-select-wrap {
    .MuiFormControl-root {
      .MuiInputBase-root {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 18px;
        color: rgba(255, 255, 255, 0.36);
      }
      .MuiInputBase-input {
        padding: 17.5px 25px;
        line-height: 20px;
        border-radius: 18px;
      }
      .MuiSelect-select {
        // background: rgba(255, 255, 255, 0.1);
        &[aria-expanded="true"] {
          & + input + .select-arrow {
            transform: rotate(180deg);
          }
        }
      }
      .MuiOutlinedInput-notchedOutline {
        top: 0;
        height: 58px;
        border-radius: 18px;
        padding: 0 15px;
        border-color: rgba(255, 255, 255, 0.1);
        legend {
          display: none;
        }
      }
      .select-arrow {
        position: absolute;
        right: 15px;
        top: calc(100% - 32px);
        width: 12px;
        height: 6px;
        pointer-events: none;
        transition: all ease-in-out 0.3s;
      }
    }
  }
}

.add-new-address-form {
  .auth-input-wrap {
    .auth-form-control {
      .auth-input {
        input {
          background: rgba(255, 255, 255, 0.1);
          color: rgba(255, 255, 255, 0.36);
          border-color: rgba(255, 255, 255, 0.1);
        }
        .MuiInputBase-adornedEnd {
          background: rgba(255, 255, 255, 0.1);
          color: rgba(255, 255, 255, 0.36);
          border-color: rgba(255, 255, 255, 0.1);
          input {
            color: rgba(255, 255, 255, 0.36);
          }
        }
      }
    }
  }
}

.btn-wrap {
  .signbuttonMarket {
    min-height: 52px;
    border-radius: 16px;
    font-size: 16px;
    &.signbuttonMarketSecondary {
      background: rgba(255, 255, 255, 0.1);
    }
  }
}

.forgot-link {
  display: block;
  text-align: center;
  margin-top: 20px;
}

.social-btns-wrap {
  .MuiDivider-root {
    color: rgba(255, 255, 255, 0.5);
    text-transform: capitalize;
    margin: 40px 0;
    font-family: $font-montserrat;
    font-weight: 500;
    opacity: 1;
    &::before,
    &::after {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }
  .social-btns {
    display: flex;
    justify-content: center;
  }
}

.already-text {
  margin-top: 80px;
  text-align: center;
  color: $white;
  font-family: $font-montserrat;
  font-weight: 500;
  .link {
    font-weight: 600;
  }
}
/** Mobile Auth Wrapper End **/

/** Pages Wrap **/
.page-wrap {
  padding-inline: 20px;
}
// .page-wrap-content {
//   background-image: url(../images/mobile-images/main-bg.png);
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   overflow-x: hidden;
//   position: relative;
//   z-index: 2;
//   &::after {
//     content: '';
//     position: absolute;
//     top: 370px;
//     left: -370px;
//     width: 732px;
//     height: 732px;
//     background: rgba(47, 83, 255, 1);
//     border-radius: 100%;
//     filter: blur(340px);
//     opacity: .2;
//     z-index: -1;
//   }
// }
/** Pages Wrap End **/

/** Dashboard Page **/
.dashboard-page {
  .page-wrap-content {
    margin: -25px -20px 0;
    padding: 30px 20px 0;
    border-radius: 36px 36px 0 0;
    background-color: rgba(5, 9, 25, 1);
    background-image: url(../images/mobile-images/main-content-bg.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow-x: hidden;
    position: relative;
    z-index: 2;
    &::after {
      content: "";
      position: absolute;
      top: 370px;
      left: -370px;
      width: 732px;
      // height: 732px;
      background: rgba(47, 83, 255, 1);
      border-radius: 100%;
      filter: blur(340px);
      opacity: 0.2;
      z-index: -1;
    }
  }
}
/** Dashboard Page End **/

/** Categories Section **/
.categories-sec {
  margin-bottom: 30px;
  .categories-widget {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
    .MuiAvatar-root {
      width: 78px;
      height: 78px;
      background: rgba(255, 255, 255, 0.1);
      overflow: visible;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: -25px;
        left: 8px;
        width: 62px;
        height: 62px;
        background: linear-gradient(180deg, #2f53ff 0%, #2fc1ff 100%);
        opacity: 0.3;
        border-radius: 100%;
        filter: blur(25px);
      }
      img {
        border-radius: 50%;
      }
    }
    .title {
      font-family: $font-montserrat;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.5);
    }
  }
  &.brands-categorie-sec {
    .categories-widget {
      .MuiAvatar-root {
        &::after {
          display: none;
        }
      }
    }
  }
}
/** Categories Section End **/

/** New Trending Section **/
.new-trending-sec {
  margin-bottom: 30px;
}

.new-trending-card {
  cursor: pointer;
  &.MuiCard-root {
    border-radius: 16px;
  }
  .MuiCardMedia-root,
  .MuiCardMedia-media {
    &:has(.new-trending-media-caption) {
      position: relative;
    }
    @media (max-width: 767px) {
      object-fit: fill;
    }
  }
  .new-trending-media-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 26px;
    line-height: 26px;
    background: rgba(23, 23, 23, 0.7);
    padding-inline: 10px;
    font-weight: 500;
    color: $white;
  }
  .MuiCardContent-root {
    padding: 10px;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-size: 10px;
    font-family: "Public Sans";
    font-weight: 200;
    color: rgba(205, 205, 205, 1);
    margin-bottom: 2px;
  }
  .description {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    font-weight: 200;
    font-size: 12px;
    // TODO: color is not matched by design
    // color: rgba(205, 205, 205, 1);
    color: rgba(255, 255, 255, 0.9);
  }
}
/** New Trending Section End **/

/** Featured Creators Section **/
.featured-creators-card {
  display: flex;
  align-items: center;
  gap: 20px;
  &.MuiCard-root {
    border-radius: 16px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    padding: 15px;
    background: rgba(255, 255, 255, 0.1);
  }
  .MuiCardMedia-media {
    width: 79px;
    min-width: 79px;
    height: 79px;
    border-radius: 8px;
  }
  .MuiCardContent-root {
    padding: 0;
  }
  .title {
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 6px;
  }
  .description {
    font-size: 10px;
    font-weight: 500;
    color: rgba(205, 205, 205, 1);
  }
}
/** Featured Creators Section End **/

/** Product Card **/
.product-card {
  border-radius: 0;
  background: transparent;
  margin-bottom: 20px;
  .MuiCardMedia-root {
    border-radius: 20px !important;
    margin-bottom: 20px;
    height: 240px;
    position: relative;
    .product-card-media-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 20px;
    }
    .product-card-actions-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      .icon-btn {
        border-radius: 8px;
      }
      .product-card-actions-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        .multi-btns {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          border-radius: 8px;
          border: 1px solid rgba(255, 255, 255, 0.08);
          background: rgba(255, 255, 255, 0.12);
          padding: 4px 5px;
          .multi-btn {
            min-width: 32px;
            min-height: 32px;
            border-radius: 8px;
            border: 1px solid transparent;
            background: transparent;
            &:hover,
            &.active {
              border-color: rgba(255, 255, 255, 0.08);
              background-color: rgba(255, 255, 255, 0.12);
            }
          }
        }
      }
    }
    .product-card-actions-footer {
      .MuiButtonBase-root {
        min-width: 32px;
        min-height: 32px;
        border-radius: 8px;
        color: $white;
        border-color: rgba(255, 255, 255, 0.08);
        background: rgba(255, 255, 255, 0.12);
        position: relative;
        z-index: 100;
      }
    }
  }
  .MuiCardContent-root {
    padding: 0;
  }
  .title {
    display: block;
    font-size: 24px;
    font-weight: 600;
    font-family: $font-montserrat;
    color: $white;
    margin-bottom: 10px;
  }
  .description {
    font-size: 16px;
    font-family: $font-montserrat;
    color: rgba(205, 205, 205, 1);
    line-height: 32px;
    letter-spacing: 1px;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
/** Product Card End **/

/** Product Authenticity **/
.product-authenticity {
  border-radius: 16px;
  background: rgba(24, 28, 31, 1);
  color: $white;
  margin-bottom: 20px;
  &::before {
    display: none;
  }
  &.MuiAccordion-root {
    &.Mui-expanded {
      margin: 0 0 20px;
    }
  }
  .MuiAccordionSummary-root {
    min-height: 52px;
    padding: 0 20px;
    &.Mui-expanded {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
    .MuiAccordionSummary-expandIconWrapper {
      svg {
        font-size: 30px;
        fill: $primaryMain;
      }
    }
    .MuiAccordionSummary-content {
      &.Mui-expanded {
        margin: 12px 0;
      }
    }
  }
  .MuiAccordionDetails-root {
    padding: 20px;
  }
  .accordion-caption-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
  .link {
    gap: 10px;
    font-size: 13px;
  }
}
/** Product Authenticity End **/

/** Creators Wrap **/
.creator-wrap {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 20px 0;
  .avatar-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 64px;
    height: 64px;
    background: linear-gradient(117.85deg, #2fc1ff -3.19%, #2f53ff 142.59%);
  }
  .MuiAvatar-root {
    width: 61px;
    height: 61px;
  }
  .title {
    display: block;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 2%;
    color: $white;
    margin-bottom: 5px;
  }
  .subtitle {
    font-size: 16px;
    letter-spacing: 2%;
    color: rgba(205, 205, 205, 1);
  }
}
/** Creators Wrap End **/

/** Properties Section **/
.properties-sec {
  margin-bottom: 55px;
  .properties-tabs {
    .MuiTabs-root {
      min-height: 46px;
    }
    .MuiTabs-flexContainer {
      border: 1px solid rgba(255, 255, 255, 0.08);
      border-radius: 8px;
      align-items: center;
      justify-content: stretch;
      margin-bottom: 20px;
      .MuiButtonBase-root {
        min-width: inherit;
        max-width: inherit;
        flex: 1 0;
        color: $white;
        font-weight: 600;
        border-radius: 7px;
        &.Mui-selected {
          .MuiTouchRipple-root {
            background: rgba(255, 255, 255, 0.12);
            filter: blur(36px);
          }
        }
      }
    }
    .MuiTabs-indicator {
      display: none;
    }
    .properties-tabs-content {
      border-radius: 16px;
      background: rgba(24, 28, 31, 1);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
      padding: 20px;
      .properties-list {
        padding: 20px;
        &:not(:last-child) {
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
      }
      .MuiTypography-root {
        font-size: 13px;
        font-weight: 500;
        color: $white;
      }
    }
    .MuiTabPanel-root {
      padding: 0;
      // min-height: 350px;
      textarea {
        width: 100%;
        height: 100%;
        background: transparent;
        border: none;
        color: rgba(139, 141, 143, 1);
        resize: none;
        font-weight: 500;
        line-height: 20px;
        &:focus {
          border: none;
          outline: none;
        }
      }
    }
  }
  // .properties-content {
  //   border-radius: 16px;
  //   background: rgba(24, 28, 31, 1);
  //   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
  //   padding: 20px;
  // }
  // .properties-card {
  //   border: 1px solid $primaryMain;
  //   color: $white;
  //   border-radius: 10px;
  //   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  //   text-align: center;
  //   &.active {
  //     background: linear-gradient(138.3deg, #2F53FF -0.85%, #2FC1FF 131.63%);
  //   }
  //   .MuiCardContent-root {
  //     padding: 20px 10px;
  //   }
  //   .subtitle {
  //     display: block;
  //     font-weight: 500;
  //     margin-bottom: 12px;
  //   }
  //   .title {
  //     display: block;
  //     font-size: 16px;
  //     font-weight: 700;
  //     margin-bottom: 12px;
  //   }
  //   .descripition {
  //     font-size: 12px;
  //     font-weight: 500;
  //   }
  // }
}
/** Properties Section End **/

/** Activity Section **/
.activity-sec {
  margin-bottom: 20px;
  .activity-content {
    border-radius: 16px;
    background: rgba(24, 28, 31, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    padding: 20px;
    .MuiFormControl-root {
      margin-bottom: 20px;
      .MuiFormLabel-root {
        transform: translate(14px, 7px) scale(1);
        color: rgba(204, 201, 201, 1);
        &.Mui-focused,
        &.MuiFormLabel-filled {
          color: $primaryMain;
          transform: translate(22px, -9px) scale(0.75);
        }
      }
      .MuiInputBase-root {
        background: transparent;
        border-radius: 8px;
      }
      .MuiInputBase-input {
        padding: 7px 15px;
      }
      .MuiSelect-select {
        color: rgba(204, 201, 201, 1);
        &[aria-expanded="true"] {
          & + input + .select-arrow {
            transform: rotate(180deg);
          }
        }
      }
      .MuiOutlinedInput-notchedOutline {
        border-radius: 8px;
        padding: 0 15px;
        border-color: rgba(205, 205, 205, 1);
      }
      .select-arrow {
        position: absolute;
        right: 15px;
        top: calc(100% - 22px);
        width: 12px;
        height: 6px;
        pointer-events: none;
        transition: all ease-in-out 0.3s;
      }
    }
  }
}
/** Activity Section End **/

/** Portfolio Section **/
.portfolio-sec {
  min-height: 20vh;
  .MuiTabs-root {
    min-height: inherit;
    .MuiTabs-flexContainer {
      border-color: rgba(205, 205, 205, 0.5);
    }
    .MuiButtonBase-root {
      padding: 0 0 13px;
      min-height: inherit;
      min-width: inherit;
      color: rgba(205, 205, 205, 1);
      font-weight: 500;
      font-size: 15px;
      &:not(:last-child) {
        margin-right: 30px;
      }
      &.Mui-selected {
        color: $white;
      }
    }
    .MuiTabs-indicator {
      height: 4.5px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background: linear-gradient(138.3deg, #2f53ff -0.85%, #2fc1ff 131.63%);
    }
  }
  .MuiTabPanel-root {
    padding: 20px 0 0;
  }
}
/** Portfolio Section End **/

/** User Profile Page **/
.user-profile-cover {
  margin: -111px -20px 0;
  img {
    width: 100%;
    object-fit: cover;
    height: 240px;
  }
}

.user-profile-card {
  border-radius: 42px 42px 0 0;
  background: rgba(5, 9, 25, 1);
  margin: -70px -20px 0;
  padding: 20px;
  position: relative;
  z-index: 2;
}

.user-profile-info-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  margin-bottom: 15px;
  .avatar-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -100px 0 15px;
    border-radius: 50%;
    width: 163px;
    height: 163px;
    // background: linear-gradient(117.85deg, #2FC1FF -3.19%, #2F53FF 142.59%);
    background: rgba(255, 255, 255, 0.2);
    position: relative;
    .icon-btn {
      position: absolute;
      bottom: 7px;
      right: 7px;
      background: #ffffff1f;
      border-color: #ffffff14;
      backdrop-filter: blur(24px);
      min-width: 40px;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 17px;
    }
  }
  .MuiAvatar-root {
    width: 143px;
    height: 143px;
  }
  .user-profile-info-caption {
    .title {
      display: block;
      font-size: 22px;
      font-weight: 600;
      line-height: 28px;
      color: $white;
      margin-bottom: 5px;
    }
    .description {
      font-weight: 200;
      line-height: 16px;
      color: rgba(205, 205, 205, 1);
      margin-bottom: 5px;
    }
    .copy-link {
      font-weight: 200;
      line-height: 16px;
      color: rgba(47, 83, 255, 1);
    }
  }
}

.user-profile-list-card {
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  text-align: center;
  margin-bottom: 30px;
  .MuiCardContent-root {
    padding: 20px 0;
  }
  .user-profile-list-caption {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    .MuiAvatar-root {
      width: 38px;
      height: 38px;
      margin-bottom: 5px;
    }
    .description {
      font-size: 13px;
      line-height: 15px;
      color: rgba(102, 102, 102, 1);
    }
    .title {
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      color: $white;
    }
    &.user-profile-list-caption-b-r {
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

.user-profile-edit-form {
  .auth-input-wrap {
    .auth-form-control {
      .auth-input {
        input {
          background: #ffffff1a;
          border-color: #ffffff1a;
          backdrop-filter: blur(24px);
        }
      }
    }
  }
}
/** User Profile Page End **/

/** Order Pages **/
.order-summary-page {
  .link {
    background: linear-gradient(91.04deg, #305bff 0%, #309bff 101.48%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.order-card {
  border-radius: 18px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 15px;

  .MuiCardContent-root {
    padding: 15px 10px;
  }

  .MuiStepper-root {
    .MuiStep-root {
      .MuiStepConnector-root {
        top: 6px;
        left: calc(-50% + 6px);
        right: calc(50% + 6px);
        .MuiStepConnector-line {
          border-top-width: 6px;
          border-radius: 3px;
          border-color: rgba(111, 113, 123, 1);
        }
      }
      .MuiStepLabel-iconContainer {
        position: relative;
        z-index: 2;
        .MuiSvgIcon-root {
          color: rgba(111, 113, 123, 1);
          width: 18px;
          height: 18px;
        }
        &.Mui-active {
          .MuiSvgIcon-root {
            color: $primaryMain;
          }
        }
      }
      .MuiStepLabel-label {
        margin-top: 5px;
        font-size: 12px;
        color: rgba(128, 128, 128, 1);
        font-weight: 400;
        @media (min-width: 567px) {
          font-size: 14px;
        }
        &.Mui-active {
          // color: $primaryMain;
          background: linear-gradient(117.85deg, #2fc1ff -3.19%, #2f53ff 142.59%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      &.Mui-completed {
        .MuiStepLabel-iconContainer {
          .MuiSvgIcon-root {
            color: $primaryMain;
          }
        }
        & + .MuiStep-root {
          .MuiStepConnector-line {
            border-color: $primaryMain;
          }
        }
      }
    }
  }

  .order-content {
    .title {
      font-size: 16px;
      color: $white;
      font-weight: 500;
      &.subtitle {
        font-size: 14px;
      }
    }
    .description {
      font-size: 14px;
      color: rgba(128, 128, 128, 1);
      font-weight: 500;
    }
    .signbuttonMarket {
      min-height: 32px;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 600;
      padding: 6px 7px;
    }
  }

  .order-price-title {
    font-size: 16px;
    color: $white;
    font-weight: 600;
    margin-bottom: 10px;
  }
  .order-price-caption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 5px;
    .MuiTypography-root {
      font-size: 14px;
      color: $white;
      font-weight: 500;
      font-family: $font-montserrat;
      &.f-weight-600 {
        font-weight: 600;
      }
    }
  }
  .MuiDivider-root {
    border-bottom: 2px dashed rgba(101, 147, 225, 1);
    opacity: 1;
    margin: 10px 0;
  }

  .order-safe-content {
    svg {
      min-width: 30px;
    }
  }

  &.my-order-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 10px;
    .MuiCardMedia-root {
      min-width: 110px;
      max-width: 110px;
      width: 110px;
      flex: 1 1 110px;
      height: 110px;
      border-radius: 18px;
      border: 2px solid rgba(255, 255, 255, 0.1);
    }
    .MuiCardContent-root {
      padding: 0;
      flex: 1 1 auto;
    }
    .order-content {
      .title {
        margin-bottom: 7px;
      }
      .description {
        margin-bottom: 7px;
      }
    }
    .order-tags-wrap {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 7px;
      .tag {
        border-radius: 8px;
        background: rgba(24, 28, 31, 1);
        border: 1px solid rgba(33, 148, 255, 1);
        padding: 0 10px;
        font-size: 10px;
        letter-spacing: 2px;
        text-align: center;
        color: rgba(77, 169, 255, 1);
        & + .tag {
          min-width: 48px;
        }
      }
    }
    .price-status-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      flex-direction: row;
      .price-status-btn {
        flex: 1 1 auto;
        background: rgba(255, 255, 255, 0.12);
        border: 1px solid rgba(255, 255, 255, 0.08);
        border-radius: 10px;
        font-size: 12px;
        font-weight: 600;
        color: $white;
        text-align: center;
        padding: 0 10px;
        min-height: 29px;
        line-height: 29px;
        cursor: pointer;
      }
    }
  }
}

.order-item-wrap {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
  .order-item-img {
    object-fit: cover;
    width: 124px;
    min-width: 124px;
    height: 121px;
    border-radius: 15px;
    border: 1px solid rgba(117, 117, 117, 0.3);
    object-fit: contain;
  }
  .order-item-caption {
    .title {
      font-size: 16px;
      color: $white;
      font-weight: 500;
      margin-bottom: 10px;
      line-height: 20px;
    }
    .description {
      font-size: 14px;
      color: rgba(128, 128, 128, 1);
      font-weight: 400;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

.order-quntity-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  .MuiFormControl-root {
    width: 124px;
    .MuiFormLabel-root {
      transform: translate(14px, 9px) scale(1);
      color: rgba(128, 128, 128, 1);
      &.Mui-focused,
      &.MuiFormLabel-filled {
        color: $primaryMain;
        transform: translate(22px, -9px) scale(0.75);
      }
    }
    .MuiInputBase-root {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 12px;
    }
    .MuiInputBase-input {
      padding: 9.5px 15px;
      line-height: 20px;
    }
    .MuiSelect-select {
      color: rgba(128, 128, 128, 1);
      &[aria-expanded="true"] {
        & + input + .select-arrow {
          transform: rotate(180deg);
        }
      }
    }
    .MuiOutlinedInput-notchedOutline {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 12px;
      padding: 0 15px;
      border-color: rgba(255, 255, 255, 0.1);
    }
    .select-arrow {
      position: absolute;
      right: 15px;
      top: calc(100% - 22px);
      width: 12px;
      height: 6px;
      pointer-events: none;
      transition: all ease-in-out 0.3s;
      path {
        stroke: rgba(128, 128, 128, 1);
      }
    }
  }
  .order-quntity-title {
    font-size: 18px;
    font-weight: 500;
    color: rgba(31, 212, 79, 1);
    &.normal {
      color: rgba(128, 128, 128, 1);
    }
  }
}

.deliver-date {
  font-size: 14px;
  color: rgba(128, 128, 128, 1);
  font-weight: 400;
  margin-bottom: 15px;
}
/** Order Pages End **/

.third-web-connect-button {
  background-color: transparent;
  background-image: url("../images/connect-wallet-mobile.svg");
  background-position: center;
  background-repeat: no-repeat;
  min-width: 50px !important;
  width: 50px !important;
  height: 50px !important;
  border: none;
}

.tw-connected-wallet {
  background-color: transparent;
  background-image: url("../images/connect-wallet-mobile.svg");
  background-position: center;
  background-repeat: no-repeat;
  min-width: 50px !important;
  width: 50px !important;
  height: 50px !important;
  border: none;
  > div {
    display: none !important;
  }
}

.video-react .video-react-big-play-button {
  display: none !important;
  font-size: 3em;
  line-height: 1.5em;
  height: 45px !important;
  width: 46px !important;
  display: block;
  position: absolute;
  top: 10px;
  left: 10px;
  padding: -5px;
  cursor: pointer;
  opacity: 1;
  border: none !important;
  background-color: #fff !important;
  border-radius: 0.3em;
  transition: all 0.4s;
  border-radius: 30px;
}
.video-wrapper {
  position: relative;
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.loader-inner-circle-mobile {
  position: absolute;
  left: 13px;
  top: 12px;
  animation: 2s clockwiseSpin infinite linear;
}

.slick-dots {
  bottom: 30px !important;
  text-align: end !important;
}
